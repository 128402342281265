<script>
    import customersRequest from '../requests/customers.request';
    export default {
        methods: {
            getCustomer(id) {
                this.loading = true
                customersRequest.getCustomersByColumn('customer_id', id)
                    .then((response) => {
                        this.customer = response.data.customer
                        this.links = response.data.links
                        document.title = this.customer.customer_name + ' | QrProfileHub.com'
                        const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
                        link.type = 'image/x-icon';
                        link.rel = 'shortcut icon';
                        link.href = this.$imagePath + this.customer.customer_logo;
                        document.getElementsByTagName('head')[0].appendChild(link);

                        // set theme color as background color
                        

                        this.loading = false
                    })
                    .catch((error) => {
                        // go to home page
                        this.$router.push('/')
                    })
            },
            openLink(link) {
                window.open(link, '_blank')
            }
        },
        mounted() {
            this.$vuetify.rtl = true
            const id = this.$route.params.id
            this.getCustomer(id)
        },
        data() {
            return {
                loading: false,
                customer: {},
                links: [],
            }
        },
    }
</script>
<template>
    <div>
        <v-progress-circular :value="20" v-if="loading" indeterminate></v-progress-circular>
        <v-container v-else fluid style="min-height: 100vh;text-align: center;"
            :style="{backgroundColor:customer.customer_background_color,color:customer.customer_text_color}">
            <v-layout row wrap justify-space-around v-if="customer.customer_name">
                <v-flex xs12 lg4 xl4 class="pa-5">
                    <v-img :src="$imagePath+customer.customer_logo" :alt="customer.customer_name" width="160px"
                        class="mx-auto" style="border-radius: 50%;"></v-img>
                    <br>
                    <h2 style="font-weight: 800;">
                        {{ customer.customer_name }}
                    </h2>
                    <!-- <p class="mt-0" style="font-weight: 200;">
                        {{ customer.customer_bio.toUpperCase() }}
                    </p> -->
                    <p style="font-weight: 400;" class="mt-10">
                        {{ customer.customer_about }}
                    </p>
                    <br>
                    <v-layout row wrap justify-space-around>
                        <!-- <v-flex xs6 v-for="(item, index) in links" :key="index">
                            <v-card outlined class="ma-3" style="border-radius: 10px;" @click="openLink(item.customer_link_value)">
                                <v-card-text class="px-2">
                                    <v-img class="mx-0" :src="$imagePath+item.link_icon_white" :alt="item.link_name"
                                        width="70px" height="70px" style="border-radius: 10px;"></v-img>
                                </v-card-text>
                                <v-card-title class="mt-n4">
                                    {{ item.link_name  }}
                                </v-card-title>
                            </v-card>
                        </v-flex> -->
                        <v-flex xs6 class="pa-2">
                            <v-btn outlined block x-large rounded :style="{color:customer.customer_text_color}"
                                :href="'tel:'+customer.customer_phone">
                                پەیوەندی
                                <v-spacer></v-spacer>
                                <v-icon right>mdi-phone</v-icon>
                            </v-btn>
                        </v-flex>
                        <v-flex xs6 class="pa-2">
                            <v-btn outlined block x-large rounded :style="{color:customer.customer_text_color}"
                                :href="customer.customer_location">
                                ناونیشان
                                <v-spacer></v-spacer>
                                <v-icon right>mdi-map-marker</v-icon>
                            </v-btn>
                        </v-flex>
                        <v-flex xs12 class="pa-2"
                            v-if="customer.customer_catalog_link != '' && customer.customer_catalog_link != null && customer.customer_catalog_link">
                            <v-btn outlined block x-large rounded :style="{color:customer.customer_text_color}"
                                :href="customer.customer_catalog_link">
                                {{ customer.customer_catalog_text }}
                                <v-spacer></v-spacer>
                                <v-icon right>mdi-file-document</v-icon>
                            </v-btn>
                        </v-flex>
                        <v-flex xs2 v-for="(item, index) in links" :key="index" class="text-center pa-4">
                            <v-img class="mx-auto" :src="$imagePath+item.link_icon_white" :alt="item.link_name"
                                width="40px" height="40px" style="border-radius: 10px;"
                                @click="openLink(item.customer_link_value)"></v-img>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
        </v-container>
        <v-footer fixed app style="direction: ltr;"
            :style="{backgroundColor:customer.customer_background_color,color:customer.customer_text_color}">
            All Rights Reserved &copy; {{ new Date().getFullYear() }}
        </v-footer>
    </div>
</template>